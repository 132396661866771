.App {
    height: 100%;
    max-height: 100%;
    width: 100%;
}

.avatar-uploader > .ant-upload {
    width: 128px;
    height: 128px;
}

/**
* Resolve success field in warning parent to not have these to be red or oragen
 */
div.ant-form-item {
    padding: 0.5em;
    margin: 0;
    /* Remove because it decentralized warning icon on add button margin: 0.3em;*/
    background-color: rgb(250, 250, 250);
}

*:focus {
    outline: transparent !important;
}

.ant-form-item-has-warning, .ant-form-item-has-warning .ant-upload.ant-upload-drag {
    background-color: #FFF8E0 !important;
}

.ant-form-item-has-error, .ant-form-item-has-error .ant-upload.ant-upload-drag {
    background-color: #FDF3F3 !important;
}

.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error),
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-upload.ant-upload-drag {
  background-color: rgb(250, 250, 250) !important;
}

.ant-form-item-has-error .ant-form-item-control-input-content .ant-upload-drag-container {
    border: 1px solid #ff4d4f;
    min-height: 32px;
}

.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-affix-wrapper,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-select:not(.ant-select-borderless) .ant-select-selector {
    border: 1px solid #d9d9d9 !important;
}

.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error).ant-input-affix-wrapper,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input:hover,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-affix-wrapper:hover,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-number,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-picker,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input:focus,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-affix-wrapper:focus,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-focused,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-upload-drag,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-upload-drag-container,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-affix-wrapper-focused {
    border-color: #d9d9d9 !important;
}

.ant-form-item-has-success .ant-form-item-control-input-content .ant-upload-drag-container,
.ant-form-item-has-success, .ant-form-item-has-success .ant-upload-drag {
    background-color: #F4FDF3 !important;
}

.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-form-item-explain,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-form-item-split {
    color: rgba(0, 0, 0, 0.45) !important;
}


.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error).ant-form-item-has-feedback .ant-form-item-children-icon {
    color: #52c41a !important;
}

.ant-breadcrumb-separator {
    padding: 1em;
}

.inovua-react-toolkit-checkbox__icon-wrapper {
    border: 1px solid #cdcecd;
}

.ant-form-item-explain {
    z-index: 1;
}

/**
End resolve warning
 */
.ant-form-item-explain,
.ant-col.ant-form-item-label label {
    max-width: 100%;
}

.ant-form-small .ant-form-item-label > label {
    height: auto !important;
}

.ant-form-item-control {
    align-self: center;
    width: 100%;
}


.ant-form-item-explain div[role=alert] span {
    text-overflow: ellipsis;
    max-width: calc(100%);
    overflow: hidden;
    display: inline-block;
    flex-wrap: wrap;
    padding-right: 15px;
    white-space: initial !important;

    text-transform: lowercase;
    text-align: left;
    /*white-space: nowrap;*/
}

.ant-col-24 {
    width: 100%;
}

.ant-col.ant-form-item-label label span:first-letter {
    text-transform: capitalize;
}

.column {
    min-width: 0
}

.is-text-overflow {
    flex: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Upload button overflow parent*/
.ant-upload.ant-upload-drag {
    overflow: hidden;
}


/* Form label size */
.message.is-small .ant-form-item-label > label {
    height: fit-content !important;
}

/* Form label size */
.ant-form-item-label > label {
    font-size: 12px;
    height: fit-content;
}

.ant-input-affix-wrapper-readonly {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
    user-select: none;
}

.ant-input[readonly] {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    cursor: not-allowed !important;
    opacity: 1 !important;
}

.ant-menu-inline, .ant-menu-vertical, .ant-menu-vertical-left {
    border: 0px !important;
}

.ant-form-horizontal .ant-form-item {
    -webkit-box-orient: horizontal !important;
    -ms-flex-direction: row !important;
    flex-direction: row !important;
}

.ant-tabs-tab {
    max-width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.ant-upload-drag-container, .ant-upload.ant-upload-drag .ant-upload-btn {
    display: block !important;
}


.ant-checkbox-indeterminate .ant-checkbox-inner::after {
    background-color: #faad14 !important;
}

.kr-embedded .kr-payment-button {
    text-align: center;
    background-color: #faad14 !important;
}

.ant-input-affix-wrapper-readonly {
    border: 1px solid #d9d9d9 !important;
}

.ant-btn-dark {
    color: #fff;
    background-color: #343a40;
    border-color: #343a40;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.ant-btn-dark-disabled,
.ant-btn-dark.disabled,
.ant-btn-dark[disabled],
.ant-btn-dark-disabled:hover,
.ant-btn-dark.disabled:hover,
.ant-btn-dark[disabled]:hover,
.ant-btn-dark-disabled:focus,
.ant-btn-dark.disabled:focus,
.ant-btn-dark[disabled]:focus,
.ant-btn-dark-disabled:active,
.ant-btn-dark.disabled:active,
.ant-btn-dark[disabled]:active,
.ant-btn-dark-disabled.active,
.ant-btn-dark.disabled.active,
.ant-btn-dark[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-dark:hover,
.ant-btn-dark:focus {
    color: #fff !important;
    background-color: rgba(52, 58, 64, 0.75) !important;
    border-color: rgba(52, 58, 64, 0.1) !important;
}

.ant-btn-dark:active,
.ant-btn-dark.active {
    color: #fff !important;
    background-color: #1d2124 !important;
    border-color: #1d2124 !important;
}

.ant-btn-background-ghost.ant-btn-dark {
    color: #343a40 !important;
    background: transparent !important;
    border-color: #343a40 !important;
    text-shadow: none !important;
}

.ant-btn-background-ghost.ant-btn-dark:hover,
.ant-btn-background-ghost.ant-btn-dark:focus {
    color: rgba(52, 58, 64, 0.75) !important;
    background: transparent !important;
    border-color: rgba(52, 58, 64, 0.75) !important;
}

.ant-btn-background-ghost.ant-btn-dark:active,
.ant-btn-background-ghost.ant-btn-dark.active {
    color: #343a40 !important;
    background: transparent !important;
    border-color: #1d2124 !important;
}

.ant-btn-lightdark {
    color: #fff !important;
    background-color: #6c757d !important;
    border-color: #6c757d !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.ant-btn-lightdark-disabled,
.ant-btn-lightdark.disabled,
.ant-btn-lightdark[disabled],
.ant-btn-lightdark-disabled:hover,
.ant-btn-lightdark.disabled:hover,
.ant-btn-lightdark[disabled]:hover,
.ant-btn-lightdark-disabled:focus,
.ant-btn-lightdark.disabled:focus,
.ant-btn-lightdark[disabled]:focus,
.ant-btn-lightdark-disabled:active,
.ant-btn-lightdark.disabled:active,
.ant-btn-lightdark[disabled]:active,
.ant-btn-lightdark-disabled.active,
.ant-btn-lightdark.disabled.active,
.ant-btn-lightdark[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important !important;
    background-color: #f5f5f5 !important !important;
    border-color: #d9d9d9 !important !important;
    text-shadow: none !important !important;
    -webkit-box-shadow: none !important !important;
    box-shadow: none !important !important;
}

.ant-btn-lightdark:hover,
.ant-btn-lightdark:focus {
    color: #fff !important !important;
    background-color: rgba(108, 117, 125, 0.75) !important !important;
    border-color: rgba(108, 117, 125, 0.1) !important !important;
}

.ant-btn-lightdark:active,
.ant-btn-lightdark.active {
    color: #fff !important !important;
    background-color: #545b62 !important !important;
    border-color: #545b62 !important;
}

.ant-btn-background-ghost.ant-btn-lightdark {
    color: #6c757d !important;
    background: transparent !important;
    border-color: #6c757d !important;
    text-shadow: none !important;
}

.ant-btn-background-ghost.ant-btn-lightdark:hover,
.ant-btn-background-ghost.ant-btn-lightdark:focus {
    color: rgba(108, 117, 125, 0.75) !important;
    background: transparent !important;
    border-color: rgba(108, 117, 125, 0.75) !important;
}

.ant-btn-background-ghost.ant-btn-lightdark:active,
.ant-btn-background-ghost.ant-btn-lightdark.active {
    color: #6c757d !important;
    background: transparent !important;
    border-color: #545b62 !important;
}

.ant-btn-success {
    color: #fff !important;
    background-color: #28a745 !important;
    border-color: #28a745 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.ant-btn-success-disabled,
.ant-btn-success.disabled,
.ant-btn-success[disabled],
.ant-btn-success-disabled:hover,
.ant-btn-success.disabled:hover,
.ant-btn-success[disabled]:hover,
.ant-btn-success-disabled:focus,
.ant-btn-success.disabled:focus,
.ant-btn-success[disabled]:focus,
.ant-btn-success-disabled:active,
.ant-btn-success.disabled:active,
.ant-btn-success[disabled]:active,
.ant-btn-success-disabled.active,
.ant-btn-success.disabled.active,
.ant-btn-success[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-success:hover,
.ant-btn-success:focus {
    color: #fff !important;
    background-color: rgba(40, 167, 69, 0.75) !important;
    border-color: rgba(40, 167, 69, 0.1) !important;
}

.ant-btn-success:active,
.ant-btn-success.active {
    color: #fff !important;
    background-color: #1e7e34 !important;
    border-color: #1e7e34 !important;
}

.ant-btn-background-ghost.ant-btn-success {
    color: #28a745 !important;
    background: transparent !important;
    border-color: #28a745 !important;
    text-shadow: none !important;
}

.ant-btn-background-ghost.ant-btn-success:hover,
.ant-btn-background-ghost.ant-btn-success:focus {
    color: rgba(40, 167, 69, 0.75) !important;
    background: transparent !important;
    border-color: rgba(40, 167, 69, 0.75) !important;
}

.ant-btn-background-ghost.ant-btn-success:active,
.ant-btn-background-ghost.ant-btn-success.active {
    color: #28a745 !important;
    background: transparent !important;
    border-color: #1e7e34 !important;
}

.ant-btn-warning {
    color: #fff !important;
    background-color: #eca52b !important;
    border-color: #eca52b !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.ant-btn-warning-disabled,
.ant-btn-warning.disabled,
.ant-btn-warning[disabled],
.ant-btn-warning-disabled:hover,
.ant-btn-warning.disabled:hover,
.ant-btn-warning[disabled]:hover,
.ant-btn-warning-disabled:focus,
.ant-btn-warning.disabled:focus,
.ant-btn-warning[disabled]:focus,
.ant-btn-warning-disabled:active,
.ant-btn-warning.disabled:active,
.ant-btn-warning[disabled]:active,
.ant-btn-warning-disabled.active,
.ant-btn-warning.disabled.active,
.ant-btn-warning[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none !important;
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}

.ant-btn-warning:hover,
.ant-btn-warning:focus {
    color: #fff !important;
    background-color: rgba(236, 165, 43, 0.75) !important;
    border-color: rgba(236, 165, 43, 0.1) !important;
}

.ant-btn-warning:active,
.ant-btn-warning.active {
    color: #fff !important;
    background-color: #d18b13 !important;
    border-color: #d18b13 !important;
}

.ant-btn-background-ghost.ant-btn-warning {
    color: #eca52b !important;
    background: transparent !important;
    border-color: #eca52b !important;
    text-shadow: none !important;
}

.ant-btn-background-ghost.ant-btn-warning:hover,
.ant-btn-background-ghost.ant-btn-warning:focus {
    color: rgba(236, 165, 43, 0.75) !important;
    background: transparent !important;
    border-color: rgba(236, 165, 43, 0.75) !important;
}

.ant-btn-background-ghost.ant-btn-warning:active,
.ant-btn-background-ghost.ant-btn-warning.active {
    color: #eca52b !important;
    background: transparent !important;
    border-color: #d18b13 !important;
}

.ant-btn-info {
    color: #fff !important;
    background-color: #17a2b8 !important;
    border-color: #17a2b8 !important;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12) !important;
    -webkit-box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
    box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045) !important;
}

.ant-btn-info-disabled,
.ant-btn-info.disabled,
.ant-btn-info[disabled],
.ant-btn-info-disabled:hover,
.ant-btn-info.disabled:hover,
.ant-btn-info[disabled]:hover,
.ant-btn-info-disabled:focus,
.ant-btn-info.disabled:focus,
.ant-btn-info[disabled]:focus,
.ant-btn-info-disabled:active,
.ant-btn-info.disabled:active,
.ant-btn-info[disabled]:active,
.ant-btn-info-disabled.active,
.ant-btn-info.disabled.active,
.ant-btn-info[disabled].active {
    color: rgba(0, 0, 0, 0.25) !important;
    background-color: #f5f5f5 !important;
    border-color: #d9d9d9 !important;
    text-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
}

.ant-btn-info:hover,
.ant-btn-info:focus {
    color: #fff !important;
    background-color: rgba(23, 162, 184, 0.75) !important;
    border-color: rgba(23, 162, 184, 0.1) !important;
}

.ant-btn-info:active,
.ant-btn-info.active {
    color: #fff;
    background-color: #117a8b;
    border-color: #117a8b;
}

.ant-btn-background-ghost.ant-btn-info {
    color: #17a2b8;
    background: transparent;
    border-color: #17a2b8;
    text-shadow: none;
}

.ant-btn-background-ghost.ant-btn-info:hover,
.ant-btn-background-ghost.ant-btn-info:focus {
    color: rgba(23, 162, 184, 0.75);
    background: transparent;
    border-color: rgba(23, 162, 184, 0.75);
}

.ant-btn-background-ghost.ant-btn-info:active,
.ant-btn-background-ghost.ant-btn-info.active {
    color: #17a2b8;
    background: transparent;
    border-color: #117a8b;
}

.disable-animation:after {
    -webkit-animation: none !important;
    -moz-animation: none !important;
    -o-animation: none !important;
    -ms-animation: none !important;
    animation: none !important;
}

.InovuaReactDataGrid__column-header__filter-wrapper > div > .InovuaReactDataGrid__column-header__filter__binary_operator_separator {
    flex-basis: 100%;
}

/*apply only on between filter*/
.InovuaReactDataGrid__column-header__filter-wrapper > div:not([class])  {
    flex-wrap: wrap ;
    .inovua-react-toolkit-date-input__calendar-icon {
        display: none;
    }
}

.InovuaReactDataGrid__column-header__content{
    text-align: right;
    padding: 5px !important;
}

.InovuaReactDataGrid__column-header__filter-settings{
    position: absolute;
    top: 8px;
    left: 0;
}

.inovua-react-toolkit-numeric-input__spinner-wrapper{
    display: none !important;
}

.inovua-react-toolkit-numeric-input__clear-button-wrapper{
    display: none !important;
}

.inovua-react-toolkit-numeric-input {
    padding: 0 !important;
}

.inovua-react-toolkit-menu__cell.inovua-react-toolkit-menu__cell--ltr.inovua-react-toolkit-menu__cell--icon {
    display: flex;
    align-items: center;
    padding: 1em;
}

.InovuaReactDataGrid__header, .InovuaReactDataGrid__column-header__filter-wrapper {
    background-color: rgb(238, 238, 238) !important;

}

.inovua-react-toolkit-combo-box__value__display-value .translation {
    white-space: nowrap;
}

.InovuaReactDataGrid--theme-blue-light {
    font-size: 11px;
}

.ant-select-dropdown,.ant-select-dropdown >div {
    min-width: min(100%,300px) !important;
    max-width: 100% !important;
}


.ant-select-selection-item {
    max-width: min(100%,300px);
}

.ant-form-item-explain, .ant-form-item-extra {
    min-height: 0 !important;
}

*, *:focus, *:hover {
    outline: none;
}

.remove-checkbox .inovua-react-toolkit-checkbox__icon-wrapper {
    display: none;
}

.ant-form-item-has-feedback > .ant-select .ant-select-arrow, .ant-form-item-has-feedback > .ant-select .ant-select-clear, .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-arrow, .ant-form-item-has-feedback :not(.ant-input-group-addon) > .ant-select .ant-select-clear {
    right: 5px !important;
}



/*keep cell in cell*/
.InovuaReactDataGrid__cell {
    overflow: hidden !important;
}

/* reduce selector*/
.InovuaReactDataGrid__column-header__filter-wrapper .ant-select-multiple.ant-select-show-arrow .ant-select-selector, .ant-select-multiple.ant-select-allow-clear .ant-select-selector{
    padding-right: 12px !important;
}
.InovuaReactDataGrid__column-header__filter-wrapper .ant-select-clear{
    right: 2px !important;
}
.InovuaReactDataGrid__column-header__filter-wrapper .ant-select-multiple .ant-select-selection-item {
    padding-inline-start: 2px !important;
    padding-inline-end: 2px !important;
    margin-inline-end: 2px !important;
}

/* keep header small*/
.InovuaReactDataGrid__cell,
.InovuaReactDataGrid__column-header__filter-wrapper input,
.InovuaReactDataGrid__column-header__filter-wrapper span,
.InovuaReactDataGrid__column-header__content>*,
.inovua-react-toolkit-date-input__input{
    font-size:10px
}



/**
Keep button on modal
 */
.ant-modal-footer{
    display:flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
}
@media (max-width:767px) {
    .ant-modal-footer .ant-btn{
        min-height: 80px;
        min-width: 80px;
    }
    .ant-modal-footer {
        justify-content: space-between;
    }
}

.yellow-confirm .ant-popover-inner{
    background-color: #fed857 !important
}

.top-0-right-0-confirm{
    top: 0 !important;
    right: 0 !important;

    .ant-popover-inner-content{
        display: flex;
        gap:1em;
        align-items:center;
    }

    .ant-popover-message{
        padding: 0 !important;
    }
}
.top-0-right-0-confirm .ant-popover-inner-content{
    display: flex;
    gap:1em;
    align-items:center;
}

.top-0-right-0-confirm .ant-popover-message{
    padding: 0 !important;
}

.top-0-right-0-confirm .ant-popover-message > .anticon{
    top: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
    display: none;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}

.ant-radio-button-wrapper-disabled.ant-radio-button-wrapper-checked{
    background-color: #40a9ff !important;
    color: white !important;
}
.ant-input[readonly],
.ant-select-disabled.ant-select:not(.ant-select-customize-input) .ant-select-selector,
.ant-picker.ant-picker-disabled,
.ant-radio-button-wrapper-disabled,
.ant-input-affix-wrapper-readonly,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-input-affix-wrapper-readonly,
.ant-form-item:not(.ant-form-item-has-warning):not(.ant-form-item-has-error) .ant-select-disabled.ant-select:not(.ant-select-borderless) .ant-select-selector{
    background-color: white !important;
    border: none !important;
    color: rgba(0, 0, 0, 0.85) !important;
}

.inovua-react-toolkit-date-input--theme-blue-light .inovua-react-toolkit-date-input__input{
    font-size: 11px;
}


