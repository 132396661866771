body {
  background-color: transparent;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
  height: 100%;
  width: 100vw;
  max-width: 100vw;
  overflow: auto;
  padding: 0;
}

.message-header {
  background-color: transparent;
  color: black;
  text-align: center;
  padding-top: 1em;
  padding-bottom: 1em;
  font-size: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.message-body {
  overflow-x: hidden;
}

.message {
  padding: 1em;
  background-color: transparent;
  //box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 0px;
  font-size: 1rem;
}

.ant-form-item-label {
  display: flex;
  align-items: center;
}

.ant-spin-spinning {
  margin-left: 50%;
  padding: 1em;
}

.ant-timeline-item-head-custom {
  background: transparent !important;
}

element.style {}

.ant-empty {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ant-statistic {
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: rgb(250, 250, 250);

}

.ant-modal-root {
  z-index: 1006
}

.full-screen.ant-modal {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100vh;
}

.full-screen.ant-modal {
  max-width: unset;
  max-height: unset;
  margin: unset;
  width: 98vw;
  height: 100vh;
}

.full-screen.ant-modal .ant-modal-content {
  height: calc(100vh - 15px);
  width: calc(100vw - 15px);
  margin: auto;
}

.full-screen.ant-modal .ant-modal-body {
  height: calc(100% - 150px);
}

.full-screen.without-footer.ant-modal .ant-modal-body {
  height: calc(100% - 70px) !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.InovuaReactDataGrid__cell {
  background: inherit;
}

.ant-divider-horizontal {
  margin: 12px 0;
}

h2,
.h2 {
  font-weight: 600;
  font-size: 18px;
  color: #252525;
}

h3,
.h3 {
  font-weight: 600;
  font-size: 14px;
  color: #252525;
}

.ant-form-item-has-error .ant-radio-button-wrapper {
  border-color: transparent !important
}

.ant-timeline-item {
  margin: 10px;
}


/**
Keep one line title on mobile
 */
.ant-modal-title .translation {
  white-space: nowrap
}

.ant-btn {
  height: fit-content;
}

.ant-menu-inline.ant-menu-root .ant-menu-item {
  padding-left: 12px !important;
}

@media (max-width:767px) {

  .message,
  #root {
    padding: 0em;
  }
}

/**
Remove background on checkbox
 */
.ant-checkbox-indeterminate .ant-checkbox-inner::after {
  background-color: transparent !important;
}

.ant-btn-success {
  background-color: #B6DB74 !important;
  border-color: #B6DB74 !important;
}


.ant-modal {
  top: 10px
}